import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Api Service
import { api, api_delete } from "../api.service";
// Components
import Confirm from "../components/confirm";
// Forms
import CheckboxField from "./fields/checkbox-field";
import CurrencyField from "./fields/currency-field";
import DateField from "./fields/date-field";
import FileField from "./fields/file-field";
import PhoneField from "./fields/phone-field";
import SelectField, { ADD_VENDOR_OPTION_ID } from "./fields/select-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

export default function BillForm({...props }) {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [isVendorSelected, setIsVendorSelected] = useState(false);
    const [values, setValues] = useState();
    const params = useParams();
    const navigate = useNavigate();

    const id = params.id;

    const deleteProject = () => {
        api_delete(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then(() => navigate(-1))
    }

    const handleFieldChange = async (key, value, values, setValues) => {
        if (id === value || key !== 'vendorId') return
        if (value === ADD_VENDOR_OPTION_ID) {
            setIsVendorSelected(true);
            setValues({
                ...values,
                vendorId: '',
                vendorName: '',
                vendorContactFirstName: '',
                vendorContactLastName: '',
                vendorContactEmail: '',
                vendorContactPhoneNumber: '',
                vendorContactCompanyAddress: ''
            });
            return;
        }

        setIsVendorSelected(true); // Set vendor selected to true

        setValues({
            ...values,
            vendorId: '',
            vendorName: '',
            vendorContactFirstName: '',
            vendorContactLastName: '',
            vendorContactEmail: '',
            vendorContactPhoneNumber: '',
            vendorContactCompanyAddress: ''
        });

        const selectedVendorInfo = await api(`${process.env.REACT_APP_API_URL}/app/vendor/${value}`)

        setValues({ 
            ...values,
            vendorId: value,
            vendorName: selectedVendorInfo.name,
            vendorContactFirstName: selectedVendorInfo.firstName,
            vendorContactLastName: selectedVendorInfo.lastName,
            vendorContactEmail: selectedVendorInfo.email,
            vendorContactCompanyAddress: selectedVendorInfo.companyAddress,
            vendorContactPhoneNumber: selectedVendorInfo.phoneNumber,
        });
    
};

    useEffect(() => {
        if (!props.noId && id && id !== "new") {
            api(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then(x => {
                setValues(x ?? {});
                if (x && x.vendorName) {
                    setIsVendorSelected(true);
                }
            });
        }
    }, [id]);

    return <div className="sm:px-4">
        <Confirm open={confirmDelete} confirm={() => deleteProject()} cancel={() => setConfirmDelete(false)}></Confirm>
        <FormWrapper url="app/bill" id={id} {...props} callback={() => navigate(-1)} validate={() => true}>
            {(values, setValues) =>
                <>
                    <div className="text-3xl font-bold tracking-tighter text-neutral-800">Bill Details</div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <SelectField optionListName="projects" label="Project" value={values.project} onChange={(val) => setValues({ ...values, project: val })} />
                        <TextField label="Order Number" value={values.invoiceNumber} onChange={(val) => setValues({ ...values, invoiceNumber: val })} required />
                        <CheckboxField label="Mark as paid" value={values.paid} onChange={(val) => setValues({ ...values, paid: val })} />
                    </div>
                    
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
                        <CurrencyField label="Subtotal" value={values.subtotal} onChange={(val) => setValues({ ...values, subtotal: val })} min={0} />
                        <DateField label="Order Date" value={values.orderDate} onChange={(val) => setValues({ ...values, orderDate: val })} />
                        <DateField label="Due Date" value={values.dueDate} onChange={(val) => setValues({ ...values, dueDate: val })} />
                        <CheckboxField label="Tax Exempt" value={values.taxExempt} onChange={(val) => setValues({ ...values, taxExempt: val, tax: val ? 0 : values.tax })}></CheckboxField>
                        {!values.taxExempt && <CurrencyField label="Tax" value={values.tax} onChange={(val) => setValues({ ...values, tax: val })} min={0} />}
                        {values.taxExempt && <div></div>}
                    </div>

                    <div className="flex items-center space-x-5">
                        <FileField filename={values.invoiceNumber + ' bill'} value={values.document} onChange={(val) => setValues({ ...values, document: val })} className="w-1/2" />
                    </div>

                    <div className="mt-10 mb-2 text-3xl font-bold tracking-tighter text-neutral-800">Vendor Details</div>
                    <div className="grid grid-cols-1 gap-4 mb-7 sm:grid-cols-2">
                        <SelectField label="Find your vendor" value={values.vendorId} optionListName="vendors" required={!isVendorSelected} onChange={(val) => handleFieldChange('vendorId', val, values, setValues)} />
                    </div>

                        {isVendorSelected && (
                            <>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                                    <TextField label="Vendor Name" value={values.vendorName} onChange={(val) => setValues({ ...values, vendorName: val })} required />
                                    <TextField label="Vendor Contact First Name" value={values.vendorContactFirstName} onChange={(val) => setValues({ ...values, vendorContactFirstName: val })} required />
                                    <TextField label="Vendor Contact Last Name" value={values.vendorContactLastName} onChange={(val) => setValues({ ...values, vendorContactLastName: val })} required />
                                </div>

                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                                    <TextField label="Vendor Contact Email" value={values.vendorContactEmail} onChange={(val) => setValues({ ...values, vendorContactEmail: val })} required />
                                    <PhoneField label="Vendor Contact Phone Number" value={values.vendorContactPhoneNumber} onChange={(val) => setValues({ ...values, vendorContactPhoneNumber: val })} required />
                                    <TextField label="Vendor Contact Company Address" value={values.vendorContactCompanyAddress} onChange={(val) => setValues({ ...values, vendorContactCompanyAddress: val })} required />
                                </div>
                            </>
                        )}

                    {id !== "new" && (
                        <div className="relative">
                            <button onClick={(e) => { e.preventDefault(); setConfirmDelete(true) }} className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2">Delete</button>
                        </div>
                    )}
                </>
            }
        </FormWrapper>
    </div>
}