import { useState,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, api_delete } from "../api.service";
import Confirm from "../components/confirm";
import CheckboxField from "./fields/checkbox-field";
import CurrencyField from "./fields/currency-field";
import DateField from "./fields/date-field";
import FileField from "./fields/file-field";
import PhoneField from "./fields/phone-field";
import SelectField,{ADD_CUSTOMER_OPTION_ID} from "./fields/select-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

export default function InvoiceForm({...props}) {
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isCustomerSelected, setIsCustomerSelected] = useState(false);
    const [values, setValues] = useState();
    const params = useParams();
    const navigate = useNavigate();
    const id=params.id;
  
    function deleteProject() {
        api_delete(`${process.env.REACT_APP_API_URL}/app/invoice/${id}`).then(() => navigate(-1))
    }
    const handleFieldChange=async(key,value,values,setValues)=>{
       if(id===value || key!=='existentCustomer') return
       if (value === ADD_CUSTOMER_OPTION_ID) {
        setIsCustomerSelected(true);
      setValues({
        ...values,
        existentCustomer: '',
        customerName: '',
        customerContactFirstName: '',
        customerContactLastName: '',
        customerContactEmail: '',
        customerContactCompanyAddress: '',
        customerContactPhoneNumber: '',
      });
      return;
    }
    setIsCustomerSelected(true); // Set vendor selected to true
    setValues({ 
        ...values,
        existentCustomer: value,
        customerName: '',
        customerContactFirstName: '',
        customerContactLastName: '',
        customerContactEmail: '',
        customerContactCompanyAddress: '',
        customerContactPhoneNumber: '',
    })
       const selectedCustomerInfo = await api(`${process.env.REACT_APP_API_URL}/app/customer/${value}`)
        setValues({ 
            ...values,
            existentCustomer: value,
            customerName: selectedCustomerInfo.name,
            customerContactFirstName: selectedCustomerInfo.firstName,
            customerContactLastName: selectedCustomerInfo.lastName,
            customerContactEmail: selectedCustomerInfo.email,
            customerContactCompanyAddress: selectedCustomerInfo.companyAddress,
            customerContactPhoneNumber: selectedCustomerInfo.phoneNumber,
        })
    }
    useEffect(() => {
        if (!props.noId && id && id !== "new") {
            api(`${process.env.REACT_APP_API_URL}/app/invoice/${id}`).then(x => {
                setValues(x ?? {});
                if (x && x.customerName) {
                    setIsCustomerSelected(true);
                }
            });
        }
    }, [id]);

    return <div className="sm:px-4">
        <Confirm open={confirmDelete} confirm={() => deleteProject()} cancel={() => setConfirmDelete(false)}></Confirm>
        <FormWrapper url="app/invoice" id={id} {...props} callback={() => navigate(-1)} validate={() => true}>
            {(values, setValues) =>
                <>
                    <div className="text-3xl font-bold tracking-tighter text-neutral-800">Invoice Details</div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">

                        <SelectField optionListName="projects" label="Project" value={values.project} onChange={(val) => setValues({ ...values, project: val })} />
                        <TextField label="Order Number" value={values.invoiceNumber} onChange={(val) => setValues({ ...values, invoiceNumber: val })} required />

                        <CheckboxField label="Mark as paid" value={values.paid} onChange={(val) => setValues({ ...values, paid: val })} />
                    </div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
                        <CurrencyField label="Subtotal" value={values.subtotal} onChange={(val) => setValues({ ...values, subtotal: val })} min={0} />
                        <DateField label="Order Date" value={values.orderDate} onChange={(val) => setValues({ ...values, orderDate: val })} />
                        <DateField label="Due Date" value={values.dueDate} onChange={(val) => setValues({ ...values, dueDate: val })} />
                        <CheckboxField label="Tax Exempt" value={values.taxExempt} onChange={(val) => setValues({ ...values, taxExempt: val, tax: val ? 0 : values.tax })}></CheckboxField>
                        {!values.taxExempt && <CurrencyField label="Tax" value={values.tax} onChange={(val) => setValues({ ...values, tax: val })} min={0} />}
                        {values.taxExempt && <div></div>}
                    </div>

                    <div className="flex items-center space-x-5">
                        <FileField filename={values.invoiceNumber + ' bill'} value={values.document} onChange={(val) => setValues({ ...values, document: val })} className="w-1/2" />
                    </div>
                    <div className="mt-10 mb-2 text-3xl font-bold tracking-tighter text-neutral-800">Customer Details</div>
                    <div className="grid grid-cols-1 gap-4 mb-7 sm:grid-cols-2">
                        <SelectField label="Find your customer" value={values.existentCustomer} optionListName="customers" required={!isCustomerSelected} onChange={(val) => handleFieldChange('existentCustomer', val, values, setValues)} />
                    </div>
                {isCustomerSelected && (
                    <>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <TextField label="Customer Name" value={values.customerName} onChange={(val) => setValues({ ...values, customerName: val })} required />
                        <TextField label="Customer Contact First Name" value={values.customerContactFirstName} onChange={(val) => setValues({ ...values, customerContactFirstName: val })} required />
                        <TextField label="Customer Contact Last Name" value={values.customerContactLastName} onChange={(val) => setValues({ ...values, customerContactLastName: val })} required />

                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">

                        <TextField label="Customer Contact Email" value={values.customerContactEmail} onChange={(val) => setValues({ ...values, customerContactEmail: val })} required />
                        <PhoneField label="Customer Contact Phone Number" value={values.customerContactPhoneNumber} onChange={(val) => setValues({ ...values, customerContactPhoneNumber: val })} required />
                        <TextField label="Customer Contact Company Address" value={values.customerContactCompanyAddress} onChange={(val) => setValues({ ...values, customerContactCompanyAddress: val })} required />
                    </div>
                    </>
                )}

                    {id !== "new" && (
                        <div className="relative">
                            <button onClick={(e) => { e.preventDefault(); setConfirmDelete(true) }} className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2">Delete</button>
                        </div>
                    )}
                </>
            }
        </FormWrapper>
    </div>
}