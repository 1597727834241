import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CurrencyField from "./fields/currency-field";
import NumberField from "./fields/number-field";
import SelectField from "./fields/select-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";
import Button from "../components/button";
import { api } from "../api.service";
import React, { useState, useEffect } from "react";

export default function ProfileForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();

  const [checkout, setCheckout] = useState(null);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    if (query.get("success")) {
      setCheckout("success");
    }

    if (query.get("canceled")) {
      setCheckout("canceled");
    }
  }, [query]);

  async function createSubscription() {
    api(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
      lookup_key: process.env.REACT_APP_STRIPE_LOOKUP_KEY,
    }).then((res) => {
      window.location.assign(res.url);
    });
  }

  async function manageSubscription() {
    api(`${process.env.REACT_APP_API_URL}/stripe/create-portal-session`, {
      /* POST REQUEST */
    }).then((res) => {
      window.location.assign(res.url);
    });
  }

  if (checkout === "success") {
    return <SuccessDisplay onContinue={() => navigate("/")} />;
  } else if (checkout === "canceled") {
    return (
      <CanceledDisplay
        onSetupSubscription={createSubscription}
        onEditSettings={() => {
          setCheckout(null);
          setQuery({});
        }}
      />
    );
  }

  return (
    <div className="sm:px-4">
      <FormWrapper
        url="app/settings"
        noId={true}
        {...props}
        callback={() => navigate("/")}
      >
        {(values, setValues) => (
          <>
            <div className="text-3xl font-bold tracking-tighter text-neutral-800">
              Tell us more about you
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <TextField
                required
                label="Your Name"
                value={values.name}
                onChange={(val) => setValues({ ...values, name: val })}
              />
              <TextField
                required
                label="Company Legal Name"
                value={values.businessName}
                onChange={(val) => setValues({ ...values, businessName: val })}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <SelectField
                required
                optionListName={"company-types"}
                label="Business Type"
                value={values.businessType}
                onChange={(val) => setValues({ ...values, businessType: val })}
              />
              <SelectField
                required
                optionListName={"roles"}
                label="Title"
                value={values.role}
                onChange={(val) => setValues({ ...values, role: val })}
              />
              <SelectField
                required
                options={[
                  { text: "Residential", value: "Residential" },
                  { text: "Commercial", value: "Commercial" },
                  { text: "Both", value: "Both" },
                ]}
                label="Types of Projects you work on"
                value={values.businessOverview}
                onChange={(val) =>
                  setValues({ ...values, businessOverview: val })
                }
              ></SelectField>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <CurrencyField
                required
                label="Monthly Revenue"
                value={values.monthlyRevenue}
                onChange={(val) =>
                  setValues({ ...values, monthlyRevenue: val })
                }
              />
              <NumberField
                required
                label="Years in Business"
                value={values.yearsInBusiness}
                onChange={(val) =>
                  setValues({ ...values, yearsInBusiness: val })
                }
              />
              <TextField
                label="Business Website"
                value={values.businessWebsite}
                onChange={(val) =>
                  setValues({ ...values, businessWebsite: val })
                }
              />
            </div>
            <div className="mt-10 mb-5 text-3xl font-bold tracking-tighter text-neutral-800">
              Business Address
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <TextField
                label="Street"
                value={values.street}
                onChange={(val) => setValues({ ...values, street: val })}
              />
              <TextField
                label="City"
                value={values.city}
                onChange={(val) => setValues({ ...values, city: val })}
              />
              <SelectField
                optionListName={"states"}
                label="State"
                value={values.state}
                onChange={(val) => setValues({ ...values, state: val })}
              />
              <TextField
                label="Zip Code"
                value={values.zipCode}
                onChange={(val) => setValues({ ...values, zipCode: val })}
              />
            </div>

            <div className="mt-10 mb-5 text-3xl font-bold tracking-tighter text-neutral-800">
              Subscription Details
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {values.stripeCustomerId ? (
                <>
                  <div className="flex flex-row items-center capitalize">
                    Status:{" "}
                    {/* incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or (custom) 'Ending on ddd DD MMM YYYY' */}
                    {values?.stripeSubscriptionStatus.replaceAll("_", " ") ??
                      "Inactive"}
                  </div>
                  {[
                    "active",
                    "past_due",
                    "unpaid",
                    "incomplete",
                    "trialing",
                  ].includes(values?.stripeSubscriptionStatus) ? (
                    <Button
                      text={"Manage your billing information"}
                      onClick={(e) => {
                        e.preventDefault();
                        manageSubscription();
                      }}
                    />
                  ) : (
                    <Button
                      text={"Restart Subscription"} // 'incomplete_expired', 'canceled', 'Ending...'
                      onClick={(e) => {
                        e.preventDefault();
                        createSubscription();
                      }}
                    />
                  )}
                </>
              ) : (
                <Button
                  text="Setup Subscription"
                  onClick={(e) => {
                    e.preventDefault();
                    createSubscription();
                  }}
                />
              )}
            </div>
          </>
        )}
      </FormWrapper>
    </div>
  );
}

const SuccessDisplay = ({ onContinue }) => {
  return (
    <section className="flex flex-col items-center gap-6 m-6">
      <p>Subscription Confirmed</p>
      <div className="flex flex-row gap-3">
        <Button
          text="Continue to Dashboard"
          onClick={() => window.location.replace("/")}
        />
      </div>
    </section>
  );
};

const CanceledDisplay = ({ onEditSettings, onSetupSubscription }) => (
  <section className="flex flex-col items-center gap-6 m-6">
    <p>
      Order canceled -- You must subscribe to access BuildrFi. You can edit your
      account settings and checkout when ready.
    </p>
    <div className="flex flex-row gap-3">
      <Button text="Edit Settings" onClick={onEditSettings} />
      <Button text="Setup Subscription" onClick={onSetupSubscription} />
    </div>
  </section>
);
