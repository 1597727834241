import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';
import { api } from '../../api.service';
import Loading from '../../components/loading';

export const ADD_VENDOR_OPTION_ID = 'add-vendor'

export const ADD_CUSTOMER_OPTION_ID = 'add-customer'


export default function SelectField({ 
    label, 
    value, 
    onChange, 
    className, 
    labelClass, 
    options, 
    optionListName, 
    placeholder, 
    required 
}) {
    const [selectOptions, setSelectOptions] = useState(options);

    useEffect(() => {
        if (optionListName === "vendors") {
            api(`${process.env.REACT_APP_API_URL}/app/vendor`).then(vendors => {
                const newSelectOptions = vendors.map(vendor => ({ text: vendor.name, value: vendor._id }))
                setSelectOptions([{ text: '+ Add Vendor', value: ADD_VENDOR_OPTION_ID }, ...newSelectOptions])
            })
        }
        if (optionListName === "customers") {
            api(`${process.env.REACT_APP_API_URL}/app/customer`).then(customers => {
                const newSelectOptions = customers.map(customer => ({ text: customer.name, value: customer._id }))
                setSelectOptions([{ text: '+ Add Customer', value: ADD_CUSTOMER_OPTION_ID }, ...newSelectOptions])
            })
        }
     
        if (optionListName === "RBAC_roles") {
            api(`${process.env.REACT_APP_API_URL}/app/role`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "projects") {
            api(`${process.env.REACT_APP_API_URL}/app`).then(x => setSelectOptions(x.projects.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "countries") {
            api(`${process.env.REACT_APP_API_URL}/admin/country`).then(x => setSelectOptions(x.map(x => { return { text: x.name, value: x._id } })))
        }
        if (optionListName === "states") {
            setSelectOptions([{ value: 'Alabama', text: 'Alabama' },
            { value: 'Alaska', text: 'Alaska' },
            { value: 'Arizona', text: 'Arizona' },
            { value: 'Arkansas', text: 'Arkansas' },
            { value: 'California', text: 'California' },
            { value: 'Colorado', text: 'Colorado' },
            { value: 'Connecticut', text: 'Connecticut' },
            { value: 'Delaware', text: 'Delaware' },
            { value: 'District of Columbia', text: 'District of Columbia' },
            { value: 'Florida', text: 'Florida' },
            { value: 'Georgia', text: 'Georgia' },
            { value: 'Hawaii', text: 'Hawaii' },
            { value: 'Idaho', text: 'Idaho' },
            { value: 'Illinois', text: 'Illinois' },
            { value: 'Indiana', text: 'Indiana' },
            { value: 'Iowa', text: 'Iowa' },
            { value: 'Kansas', text: 'Kansas' },
            { value: 'Kentucky', text: 'Kentucky' },
            { value: 'Louisiana', text: 'Louisiana' },
            { value: 'Maine', text: 'Maine' },
            { value: 'Maryland', text: 'Maryland' },
            { value: 'Massachusetts', text: 'Massachusetts' },
            { value: 'Michigan', text: 'Michigan' },
            { value: 'Minnesota', text: 'Minnesota' },
            { value: 'Mississippi', text: 'Mississippi' },
            { value: 'Missouri', text: 'Missouri' },
            { value: 'Montana', text: 'Montana' },
            { value: 'Nebraska', text: 'Nebraska' },
            { value: 'Nevada', text: 'Nevada' },
            { value: 'New Hampshire', text: 'New Hampshire' },
            { value: 'New Jersey', text: 'New Jersey' },
            { value: 'New Mexico', text: 'New Mexico' },
            { value: 'New York', text: 'New York' },
            { value: 'North Carolina', text: 'North Carolina' },
            { value: 'North Dakota', text: 'North Dakota' },
            { value: 'Ohio', text: 'Ohio' },
            { value: 'Oklahoma', text: 'Oklahoma' },
            { value: 'Oregon', text: 'Oregon' },
            { value: 'Pennsylvania', text: 'Pennsylvania' },
            { value: 'Rhode Island', text: 'Rhode Island' },
            { value: 'South Carolina', text: 'South Carolina' },
            { value: 'South Dakota', text: 'South Dakota' },
            { value: 'Tennessee', text: 'Tennessee' },
            { value: 'Texas', text: 'Texas' },
            { value: 'Utah', text: 'Utah' },
            { value: 'Vermont', text: 'Vermont' },
            { value: 'Virginia', text: 'Virginia' },
            { value: 'Washington', text: 'Washington' },
            { value: 'West Virginia', text: 'West Virginia' },
            { value: 'Wisconsin', text: 'Wisconsin' },
            { value: 'Wyoming', text: 'Wyoming' }])
        }
        if (optionListName === "company-types") {
            setSelectOptions([{ value: 'General Contractor', text: 'General Contractor' },
            { value: 'Subcontractor/Specialty Trade', text: 'Subcontractor/Specialty Trade' },
            { value: 'Architect/Design Studio', text: 'Architect/Design Studio' },
            { value: 'Supplier/Vendor', text: 'Supplier/Vendor' },])
        }
        if (optionListName === "roles") {
            setSelectOptions([{ value: 'Company Owner', text: 'Company Owner' },
            { value: 'Project Manager', text: 'Project Manager' },
            { value: 'Other', text: 'Other' }])
        }
    }, [])
    const [query, setQuery] = useState('')

    const filtered = query === '' ? selectOptions : selectOptions.filter((x) => x?.text.toLowerCase().includes(query.toLowerCase()))
    if (!selectOptions) return <Loading></Loading>

    return <div className={className + ' mt-2'}>
        <label className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}>
            {label}  {required && <span className="text-rose-600">*</span>}
        </label>
        <div className="relative mt-1 rounded shadow-sm ">
            <Combobox value={selectOptions.find(x => x.value === value)} onChange={(e) => { onChange(e); }}>
                <div className="relative mt-1 border">
                    <div className="relative w-full overflow-hidden cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-violet-300 sm:text-sm">

                        <Combobox.Input
                            placeholder={placeholder}
                            className="w-full py-2 pl-3 pr-10 text-sm leading-5 bg-white border-none text-neutral-800 focus:ring-0"
                            onChange={(e) => setQuery(e.target.value)}
                            displayValue={(x) => x?.text}
                            required={required}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="w-5 h-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute z-40 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((x) => (
                                <Combobox.Option
                                    key={x.value}
                                    value={x.value}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 px-3  ${x.value === value ? 'bg-yellow-500 text-white' : `${x.value !== value && active ? 'bg-yellow-400 text-white' : 'text-neutral-800'}`} `
                                    }
                                >
                                    {x.text}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>

                    </Transition>
                </div>
            </Combobox>
        </div>
    </div>
}

