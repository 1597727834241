import ReactInputMask from "react-input-mask";



export default function PhoneField({ label, value, onChange, className, inputClass, labelClass, placeholder, onBlur, type, required }) {
    return <div className={className + ' mt-2'} >
        <label className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}>
            {label} {required && <span className="text-rose-600">*</span>}
        </label>
        <div className="mt-1">
            <ReactInputMask mask="999-999-9999" value={value} onChange={(e) => onChange(e.target.value)}>
                {(inputProps) => <input {...inputProps} required={required} type={type ? type : 'text'} onBlur={onBlur} placeholder={placeholder} className={`block w-full border px-3 p-2 text-neutral-800 shadow placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass}`} />
                }
            </ReactInputMask>
        </div>
    </div>
}