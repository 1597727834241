import { useState, useEffect, useCallback } from 'react';
import { apiFetchVendors, apiUpdateVendor, apiDeleteVendor } from '../service/buildrFi/vendors';

const useVendors = () => {
    const [data, setData] = useState([]);

    const fetchVendors = useCallback(async () => {
        try {
            const result = await apiFetchVendors();
            setData(result ?? []);
        } catch (error) {
            console.error("Error fetching vendors:", error);
            setData([]);
        }
    }, []);

    useEffect(() => {
        fetchVendors();
    }, [fetchVendors]);

    const updateVendor = async (updatedVendorData) => {
        try {
            await apiUpdateVendor(updatedVendorData);
            fetchVendors();
        } catch (error) {
            console.error("Error updating vendor:", error);
        }
    };

    const deleteVendor = async (vendorId) => {
        try {
            await apiDeleteVendor(vendorId);
            fetchVendors();
        } catch (error) {
            console.error("Error deleting vendor:", error);
        }
    };

    return { data, updateVendor, deleteVendor };
};

export default useVendors;
