import { PlusIcon } from "@heroicons/react/20/solid";
import { ArrowsUpDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import "@lendica/ibranchtrigger";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "./api.service";
import Button from "./components/button";
import HelpPopup from "./components/help-popup";
import SelectField from "./forms/fields/select-field";
import { ExportToCsv } from "export-to-csv";

export default function Projects() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const [showMore, setShowMore] = useState(false);
  const [sortAlg, setSortAlg] = useState({ param: "", isAscending: true });
  const [projectStatusFilter, setProjectStatusFilter] = useState("");

  const [projectsList, setProjectsList] = useState([]);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setData(x);
    });
  }, []);

  useEffect(() => {
    let total = 0;
    let owed = 0;
    let paid = 0;
    let totalSpend = 0;
    let totalEstimatedSpend = 0;

    if (data?.projects?.length)
      for (let p of data.projects) {
        let totals = getItemTotals(p._id, p.deposit);

        if (p.totalPrice) total += +p.totalPrice;
        if (totals.totalPaidToDate) paid += totals.totalPaidToDate;
        if (totals.totalOutstanding) owed += totals.totalOutstanding;
        if (totals.totalCosts) totalSpend += totals.totalCosts;
        if (p.totalEstimatedCosts)
          totalEstimatedSpend += +p.totalEstimatedCosts;
      }

    let percentageComplete = (totalSpend / totalEstimatedSpend) * 100;

    if (isNaN(percentageComplete)) percentageComplete = 0;
    percentageComplete = Math.round(percentageComplete);
    setTotal({
      total,
      owed,
      paid,
      totalSpend,
      totalEstimatedSpend,
      percentageComplete,
    });
  }, [data]);
  const linksRef = useRef();
  const linksVisible = useIntersectionObserver(linksRef, {
    rootMargin: "0px",
    threshold: 0.2,
  });
  useEffect(() => {
    if (linksVisible) {
      linksRef.current.classList.remove("invisible");
      linksRef.current.classList.add("animate-fade-up");
    }
  }, [linksVisible]);

  const contentRef = useRef();
  const contentVisible = useIntersectionObserver(contentRef, {
    rootMargin: "0px",
    threshold: 0.2,
  });
  useEffect(() => {
    if (contentVisible) {
      contentRef.current.classList.remove("invisible");
      contentRef.current.classList.add("animate-fade-up");
    }
  }, [contentVisible]);

  const getItemTotals = (projectId, depositPaid) => {
    let totalOutstanding = 0;
    let totalPaidToDate = +(depositPaid ?? 0);
    let totalCosts = 0;
    for (let i of data.invoices.filter((x) => x.project == projectId)) {
      if (i.paid) {
        totalPaidToDate += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      } else {
        totalOutstanding += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
    }
    for (let b of data.bills.filter((x) => x.project == projectId)) {
      totalCosts += +(b.tax ?? 0) + +(b.subtotal ?? 0);
    }
    return { totalOutstanding, totalPaidToDate, totalCosts };
  };

  useEffect(() => {
    setProjectsList(
      data?.projects
        ?.filter((x) => !projectStatusFilter || x.status == projectStatusFilter)
        .sort((a, b) => sortFn(a, b, sortAlg))
        .map((x) => ({
          _id: x._id,
          project_name: x.name,
          project_status: x.status,
          total_contract_value: safeCurrency(x.totalPrice),
          total_paid_to_date: safeCurrency(
            getItemTotals(x._id, x.deposit).totalPaidToDate
          ),
          total_outstanding: safeCurrency(
            getItemTotals(x._id, x.deposit).totalOutstanding
          ),
          total_costs_to_date: safeCurrency(
            getItemTotals(x._id, x.deposit).totalCosts
          ),
          percentage_completed: `${safeNumber(
            (getItemTotals(x._id, x.deposit).totalCosts /
              x.totalEstimatedCosts) *
            100
          )}%`,
        })) ?? []
    );
  }, [data, projectStatusFilter, sortAlg]);

  const exportToCsv = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: Object.keys(projectsList[0] ?? {}),
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(projectsList);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div>
        <div className="w-full max-w-6xl p-5 mx-auto sm:p-0">
          <div className="mb-10 text-3xl font-semibold text-neutral-800">
            Projects Summary
          </div>

          {data && (
            <>
              {!data?.projects.length && (
                <div className="border flex justify-center rounded shadow bg-secondary animate-fade-up animate-delay-300">
                  <Empty></Empty>
                </div>
              )}

              {data?.projects.length > 0 && (
                <div className="flex flex-col items-stretch gap-4 my-5 sm:items-end sm:flex-row sm:justify-between">
                  <div className="sm:w-96">
                    <SelectField
                      label="Project Status Filter"
                      value={projectStatusFilter}
                      onChange={setProjectStatusFilter}
                      options={[
                        { text: "All", value: "" },
                        { text: "In Review", value: "In Review" },
                        { text: "Active", value: "Active" },
                        { text: "Complete", value: "Complete" },
                      ]}
                    ></SelectField>
                  </div>
                  <Button text="Export to CSV" onClick={() => exportToCsv()} />
                </div>
              )}

              {data?.projects.length > 0 && (
                <div className="border grid grid-cols-4 rounded shadow lg:grid-cols-7 bg-secondary animate-fade-up animate-delay-300">
                  <div className="items-center hidden p-5 font-semibold tracking-tight border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Project Name"
                      accessor="name"
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="hidden p-5 font-semibold tracking-tight border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Project Status"
                      accessor="status"
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Contract Value"
                      accessor="totalPrice"
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Paid To Date"
                      accessor="paidToDate"
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Outstanding"
                      accessor={(x) =>
                        x.totalPrice !== undefined && x.paidToDate !== undefined
                          ? x.totalPrice - x.paidToDate
                          : undefined
                      }
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-neutral-800 lg:block border-slate-500">
                    <SortToggle
                      text="Costs To Date"
                      accessor={(x) =>
                        x.getItemTotals(x._id, x.deposit).totalCosts !==
                          undefined && x.paidToDate !== undefined
                          ? x.totalPrice - x.paidToDate
                          : undefined
                      }
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>
                  <div className="relative hidden p-5 font-semibold tracking-tight text-right border-b-2 text-neutral-800 lg:block border-slate-500">
                    <div className="absolute right-16 top-5">
                      <HelpPopup text="Total costs to date/total estimated costs"></HelpPopup>
                    </div>
                    <SortToggle
                      text="Percentage Complete"
                      accessor={(x) =>
                        x.costsIncurredToDate !== undefined &&
                          x.totalEstimatedCosts !== undefined
                          ? (x.costsIncurredToDate / x.totalEstimatedCosts) *
                          100
                          : undefined
                      }
                      state={sortAlg}
                      onChange={(next) => setSortAlg(next)}
                    ></SortToggle>
                  </div>

                  {/* {data?.projects
                    ?.filter((x, i) => (showMore ? true : i < 10))
                    .filter(
                      (x) =>
                        !projectStatusFilter || x.status == projectStatusFilter
                    )
                    .sort((a, b) => sortFn(a, b, sortAlg))
                    .map((x) => (
                      <>
                        <div
                          onClick={() => navigate("/project/" + x._id)}
                          className="col-span-2 p-5 font-medium tracking-wide border-b cursor-pointer text-neutral-800 lg:col-span-1 hover:underline border-slate-500"
                        >
                          {x.name}
                        </div>
                        <div className="hidden p-5 font-medium border-b text-neutral-800 lg:block border-slate-500">
                          {x.status}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {safeCurrency(x.totalPrice)}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {safeCurrency(
                            getItemTotals(x._id, x.deposit).totalPaidToDate
                          )}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {safeCurrency(
                            getItemTotals(x._id, x.deposit).totalOutstanding
                          )}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {safeCurrency(
                            getItemTotals(x._id, x.deposit).totalCosts
                          )}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {safeNumber(
                            (getItemTotals(x._id, x.deposit).totalCosts /
                              x.totalEstimatedCosts) *
                              100
                          )}
                          %
                        </div>
                        <div className="col-span-2 p-5 border-b border-slate-500 lg:hidden">
                          <div className="text-xs text-neutral-800">
                            Paid To Date -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {safeCurrency(
                                getItemTotals(x._id, x.deposit).totalPaidToDate
                              )}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Contract Value -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {safeCurrency(x.totalPrice)}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Outstanding -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {safeCurrency(
                                getItemTotals(x._id, x.deposit).totalOutstanding
                              )}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Costs To Date -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {safeCurrency(
                                getItemTotals(x._id, x.deposit).totalCosts
                              )}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Percent Complete -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {safeNumber(
                                (getItemTotals(x._id, x.deposit).totalCosts /
                                  x.totalEstimatedCosts) *
                                  100
                              )}
                              %
                            </span>
                          </div>
                        </div>
                      </>
                    ))} */}

                  {projectsList
                    ?.filter((_, i) => (showMore ? true : i < 10))
                    .map((x) => (
                      <>
                        <div
                          onClick={() => navigate("/project/" + x._id)}
                          className="col-span-2 p-5 font-medium tracking-wide border-b cursor-pointer text-neutral-800 lg:col-span-1 hover:underline border-slate-500"
                        >
                          {x.project_name}
                        </div>
                        <div className="hidden p-5 font-medium border-b text-neutral-800 lg:block border-slate-500">
                          {x.project_status}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {x.total_contract_value}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {x.total_paid_to_date}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {x.total_outstanding}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {x.total_costs_to_date}
                        </div>
                        <div className="hidden p-5 font-medium text-right border-b text-neutral-800 lg:block border-slate-500">
                          {x.percentage_completed}
                        </div>
                        <div className="col-span-2 p-5 border-b border-slate-500 lg:hidden">
                          <div className="text-xs text-neutral-800">
                            Paid To Date -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {x.total_paid_to_date}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Contract Value -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {x.total_contract_value}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Outstanding -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {x.total_outstanding}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Costs To Date -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {x.total_costs_to_date}
                            </span>
                          </div>
                          <div className="text-xs text-neutral-800">
                            Percent Complete -{" "}
                            <span className="text-sm font-medium text-neutral-800">
                              {x.percentage_completed}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                  {projectsList.length > 10 && !showMore && (
                    <>
                      <div
                        className="col-span-4 p-2 underline cursor-pointer lg:col-span-7"
                        onClick={() => setShowMore(true)}
                      >
                        Show More
                      </div>
                    </>
                  )}
                  <>
                    <div className="hidden px-5 pt-3 text-gray-200 border-t-2 lg:block border-slate-500">
                      <Button
                        onClick={() => navigate("/project/new")}
                        text={
                          <>
                            {" "}
                            <PlusIcon className="w-5 h-5"></PlusIcon> Project
                          </>
                        }
                      />
                    </div>
                    <div className="col-span-2 p-5 text-xl font-semibold leading-6 tracking-wide text-right border-t-2 text-neutral-800 lg:col-span-1 border-slate-500">
                      Total
                    </div>
                    <div className="hidden p-5 font-semibold text-right border-t-2 text-neutral-800 lg:block border-slate-500">
                      ${total.total.toLocaleString()}
                    </div>
                    <div className="hidden p-5 font-semibold text-right border-t-2 text-neutral-800 lg:block border-slate-500">
                      ${total.paid.toLocaleString()}
                    </div>
                    <div className="hidden p-5 font-semibold text-right border-t-2 text-neutral-800 lg:block border-slate-500">
                      ${total.owed.toLocaleString()}
                    </div>
                    <div className="hidden p-5 font-semibold text-right border-t-2 text-neutral-800 lg:block border-slate-500">
                      ${total.totalSpend.toLocaleString()}
                    </div>
                    <div className="hidden p-5 font-semibold text-right border-t-2 text-neutral-800 lg:block border-slate-500">
                      {total.percentageComplete}%
                    </div>

                    <div className="col-span-2 p-5 border-t-2 border-slate-500 lg:hidden">
                      <div className="text-xs text-neutral-800">
                        Paid To Date -{" "}
                        <span className="text-sm font-medium text-neutral-800">
                          ${total.paid.toLocaleString()}
                        </span>
                      </div>
                      <div className="text-xs text-neutral-800">
                        Contract Value -{" "}
                        <span className="text-sm font-medium text-neutral-800">
                          ${total.total.toLocaleString()}
                        </span>
                      </div>
                      <div className="text-xs text-neutral-800">
                        Outstanding -{" "}
                        <span className="text-sm font-medium text-neutral-800">
                          ${total.owed.toLocaleString()}
                        </span>
                      </div>
                      <div className="text-xs text-neutral-800">
                        Outstanding -{" "}
                        <span className="text-sm font-medium text-neutral-800">
                          ${total.totalSpend.toLocaleString()}
                        </span>
                      </div>
                      <div className="text-xs text-neutral-800">
                        Percent Complete -{" "}
                        <span className="text-sm font-medium text-neutral-800">
                          {total.percentageComplete}%
                        </span>
                      </div>
                    </div>

                    <div className="col-span-4 p-5 text-gray-200 border-t-2 lg:hidden border-slate-500">
                      <Button
                        onClick={() => navigate("/project/new")}
                        text={
                          <>
                            {" "}
                            <PlusIcon className="w-8 h-8"></PlusIcon> Project
                          </>
                        }
                      />
                    </div>
                  </>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function Empty() {
  const navigate = useNavigate();
  return (
    <div className="my-10 text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-800">No projects</h3>
      <p className="mt-1 text-sm text-gray-700">
        Get started by creating a new project.
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate("/project/new")}
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold bg-yellow-500 border-2 border-yellow-500 rounded-md text-neutral-800"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Project
        </button>
      </div>
    </div>
  );
}

function SortToggle({
  text,
  accessor,
  state,
  onChange,
  className,
  defaultAscending = true,
}) {
  return (
    <div
      className={`flex justify-center w-full h-full items-center text-center cursor-pointer select-none ${className}`}
      onClick={() =>
        onChange({
          accessor: accessor,
          isAscending: isCurrentAccessor(accessor, state.accessor)
            ? !state.isAscending
            : defaultAscending,
        })
      }
    >
      {text}{" "}
      <div className="flex items-center justify-center w-6 h-6 ml-1 min-w-6 min-h-6">
        <ArrowUpIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
              ? "h-6 w-6 opacity-100"
              : "h-0 w-0 opacity-0"
            } ${state.isAscending ? "rotate-0" : "rotate-180"} transition-all`}
        />
        <ArrowsUpDownIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
              ? "h-0 w-0 opacity-0"
              : "h-5 w-5 opacity-100"
            } text-gray-400 transition-all`}
        />
      </div>
    </div>
  );
}

function isCurrentAccessor(source, target) {
  if (typeof source !== typeof target) {
    return false;
  }

  if (typeof source === "function") {
    return source.toString() === target.toString();
  }

  return source === target;
}

function sortFn(a, b, { accessor, isAscending }) {
  if (typeof accessor === "function") {
    a = accessor(a);
    b = accessor(b);
  } else {
    a = a[accessor];
    b = b[accessor];
  }

  if (a === undefined) return b === undefined ? 0 : 1;
  if (b === undefined) return -1;

  return (a > b ? 1 : -1) * (isAscending ? -1 : 1);
}

function safeCurrency(value) {
  if (value === 0) {
    return "$0";
  }
  if (!value) {
    return "-";
  }
  return "$" + Number(value).toLocaleString();
}
function safeNumber(value) {
  if (value === 0) {
    return "0";
  }
  if (!value) {
    return "-";
  }
  return Number(value).toLocaleString();
}

function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}
