import { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { ExportToCsv } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import { sortFn } from '../utils';
import { validateData, vendorSchema } from '../schema';
import { TableCell, DropdownMenu } from './Table/';
import Button from './button';
import useVendors from '../hooks/useVendors';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function VendorsList() {
  return (
    <div className="flex flex-col min-h-screen">
      <div>
        <div className="w-full max-w-6xl p-5 mx-auto sm:p-0">
          <div className="mb-10 text-3xl font-semibold text-neutral-800">My Vendors</div>
          <VendorsTable />
        </div>
      </div>
    </div>
  );
}

export const VendorsTable = () => {
  const [showMore, setShowMore] = useState(false);
  const [sortAlg, setSortAlg] = useState({ param: "", isAscending: true });
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editData, setEditData] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const { data, updateVendor, deleteVendor } = useVendors();

  useEffect(() => {
    setList(
      data?.sort((a, b) => sortFn(a, b, sortAlg))
        .map((x) => ({
          _id: x?._id,
          company_name: x?.name,
          first_name: x?.firstName,
          last_name: x?.lastName,
          email: x?.email,
          company_address: x?.companyAddress,
          phone_number: x?.phoneNumber
        })) ?? []
    );
  }, [data, sortAlg]);

  const exportToCsv = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: Object.keys(list[0] ?? {}),
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(list);
  };

  const handleEdit = (index) => {
    setEditMode(index);
    setEditData(data[index]);
    setDropdownOpen(null);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this row?")) {
      deleteVendor(data[index]._id);
      toast("Vendor deleted successfully");
      setDropdownOpen(null)
    }
  };

  function changeInputStylingToInvalid(input) {
    input.style.border = "2px solid red";
  }
  const isFormValid = () => {
    const { isValid, errors } = validateData(editData, vendorSchema);
    if (!isValid) {
      toast("Validation failed: " + errors.map(e => `${e.field} ${e.message}`).join(', '));
      errors.forEach(error => {
        changeInputStylingToInvalid(document.getElementById(error.field));
      });
      return false;
    }
    return true;
  };

  const handleSave = (e) => {
    e.preventDefault()
    if (isFormValid() === true && window.confirm("Are you sure you want to save the changes?")) {
      updateVendor(editData).then(() => {
        toast("Vendor updated successfully");
      });
      setEditMode(null);
      setEditData({});
    }
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel the changes?")) {
      setEditMode(null);
      setEditData({});
    }
  };

  const handleDropdownToggle = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setDropdownOpen(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {data && (
        <>
          {!data.length && (
            <div className="border flex justify-center rounded shadow bg-secondary animate-fade-up animate-delay-300">
              <Empty />
            </div>
          )}
          <ToastContainer
            position="top-center"
            autoClose={2000}
          />
          {data.length > 0 && (
            <div className="flex justify-end mb-5">
              <Button text="Export to CSV" onClick={() => exportToCsv()} />
            </div>
          )}
          {data.length > 0 && (
            <div className="grid rounded shadow grid-cols-[repeat(6,_1fr)_auto] bg-secondary animate-fade-up animate-delay-300">
              {/* Table headers */}
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500">
                <SortToggle
                  text="Company Name"
                  accessor="name"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500">
                <SortToggle
                  text="First Name"
                  accessor="firstName"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500">
                <SortToggle
                  text="Last Name"
                  accessor="lastName"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500 break-words">
                <SortToggle
                  text="Email"
                  accessor="email"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500">
                <SortToggle
                  text="Address"
                  accessor="companyAddress"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b text-neutral-800 lg:block border-slate-500">
                <SortToggle
                  text="Phone"
                  accessor="phoneNumber"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                />
              </div>
              <div className="hidden border-b lg:block border-slate-500">
              </div>

              {data && data
                .filter((x, i) => (showMore ? true : i < 10))
                .sort((a, b) => sortFn(a, b, sortAlg))
                .map((x, index) => (
                  // Table rows
                  <div key={index} className="contents">
                    <form className='contents' onSubmit={(e) => handleSave(e)}>
                      <TableCell
                        id='name'
                        value={x.name}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        className="col-span-2 lg:col-span-1"
                      />
                      <TableCell
                        id='firstName'
                        value={x.firstName}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        isHidden={true}
                      />
                      <TableCell
                        id='lastName'
                        value={x.lastName}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        isHidden={true}
                      />
                      <TableCell
                        id='email'
                        type='email'
                        value={x.email}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        isHidden={true}
                      />
                      <TableCell
                        id='companyAddress'
                        value={x.companyAddress}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        isHidden={true}
                      />
                      <TableCell
                        id='phoneNumber'
                        value={x.phoneNumber}
                        editMode={editMode === index}
                        editData={editData}
                        setEditData={setEditData}
                        isHidden={true}
                      />
                      <DropdownMenu
                        editMode={editMode}
                        index={index}
                        dropdownOpen={dropdownOpen}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        handleDropdownToggle={handleDropdownToggle}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                      />

                      {/* data for mobile render */}
                      <div className="col-span-5 p-5 border-b border-slate-500 lg:hidden">
                        <div className="text-xs text-neutral-800">
                          <span className="text-sm font-medium text-neutral-800">First Name: </span>{x?.firstName ?? "-"}
                        </div>
                        <div className="text-xs text-neutral-800">
                          <span className="text-sm font-medium text-neutral-800">Last Name: </span>{x?.lastName ?? "-"}
                        </div>
                        <div className="text-xs text-neutral-800">
                          <span className="text-sm font-medium text-neutral-800">Email: </span>{x?.email ?? "-"}
                        </div>
                        <div className="text-xs text-neutral-800">
                          <span className="text-sm font-medium text-neutral-800">Address: </span>{x?.companyAddress ?? "-"}
                        </div>
                        <div className="text-xs text-neutral-800">
                          <span className="text-sm font-medium text-neutral-800">Phone Number: </span>{x?.phoneNumber ?? "-"}
                        </div>
                      </div>
                    </form>
                  </div>
                ))}
              {data.length > 10 && !showMore && (
                <div
                  className="col-span-4 p-2 underline cursor-pointer lg:col-span-7"
                  onClick={() => setShowMore(true)}
                >
                  Show More
                </div>
              )}
              <div className="mt-2 p-2">
                <Button onClick={() => navigate('/vendor/create')} text={<> <PlusIcon className="w-5 h-5"></PlusIcon> Vendor</>} />
              </div>
              <div className="hidden p-5 text-gray-200 border-t-2 lg:block"></div>
              <div className="col-span-4 p-5 text-gray-200 border-t-2 lg:hidden"></div>
            </div>
          )}
        </>
      )}
    </>
  );
};

function Empty() {
  const navigate = useNavigate();
  return (
    <div className="my-10 text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-800">No Vendors</h3>
      <p className="mt-1 text-sm text-gray-700">Get started by creating a new vendor.</p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/vendor/create')}
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold bg-yellow-500 border-2 border-yellow-500 rounded-md text-neutral-800"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New vendor
        </button>
      </div>
    </div>
  );
}

function SortToggle({ text, accessor, state, onChange, className, defaultAscending = true }) {
  return (
    <div
      className={`flex w-full h-full items-center text-center cursor-pointer select-none ${className}`}
      onClick={() =>
        onChange({
          accessor: accessor,
          isAscending: isCurrentAccessor(accessor, state.accessor)
            ? !state.isAscending
            : defaultAscending,
        })
      }
    >
      {text}{' '}
      <div className="flex items-center justify-center w-6 h-6 ml-1 min-w-6 min-h-6">
        <ArrowUpIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
            ? 'h-6 w-6 opacity-100'
            : 'h-0 w-0 opacity-0'
            } ${state.isAscending ? 'rotate-0' : 'rotate-180'} transition-all`}
        />
        <ArrowsUpDownIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
            ? 'h-0 w-0 opacity-0'
            : 'h-5 w-5 opacity-100'
            } text-gray-400 transition-all`}
        />
      </div>
    </div>
  );
}

function isCurrentAccessor(source, target) {
  if (typeof source !== typeof target) {
    return false;
  }

  if (typeof source === 'function') {
    return source.toString() === target.toString();
  }

  return source === target;
}

