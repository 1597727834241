export default function DateField({ label, value, onChange, className, inputClass, labelClass, onBlur, required, isValid = true, invalidText }) {

    if (value?.length > 11) value = value.split("T")[0]

    return <div className={className + ' mt-2'}>
        <div className="flex flex-wrap justify-between">
            <label className={`block text-sm font-medium leading-6 mr-2 text-neutral-800 ${labelClass}`}>
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            {(invalidText && !isValid) && <span className="text-sm font-medium leading-6 text-rose-600">{invalidText}</span>}
        </div>
        <div className="mt-1">
            <input onBlur={onBlur} required={required} type="date" value={value} onChange={(e) => onChange(e.target.value)} className={`bg-white w-full block border border-neutral-200 p-2 text-neutral-800 shadow  placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass} ${isValid ? '' : 'ring-rose-700 focus:ring-rose-600'}`} />
        </div>
    </div>
}