import TextField from "./text-field";
import PasswordField from "./password-field";
import SelectField from "./select-field";
import CurrencyField from "./currency-field";
import NumberField from "./number-field";

const USER_SECTION_FIELDS = [
  {
    id: "email",
    label: "Email",
    required: true,
    component: TextField,
  },
  {
    id: "password",
    label: "New Password",
    required: true,
    component: PasswordField,
  },
  {
    id: "name",
    label: "Name",
    required: true,
    component: TextField,
  },
  {
    id: "roles",
    label: "Role",
    required: true,
    component: SelectField,
    componentProps: {
      optionListName: "RBAC_roles"
    },
  },
  {
    id: "role",
    label: "Title",
    required: false,
    component: SelectField,
    componentProps: {
      optionListName: "roles"
    },
  },
]

const ADMIN_USER_CREATION_FORM_FIELDS = [
  { 
    title: "User Information",
    fields: USER_SECTION_FIELDS
  },
  { 
    title: "Business Information",
    fields: [
      {
        id: "businessName",
        label: "Company Legal Name",
        required: true,
        component: TextField,
      },
      {
        id: "businessType",
        label: "Company Type",
        required: true,
        component: SelectField,
        componentProps: {
          optionListName: "company-types"
        }
      },
      {
        id: "businessOverview",
        label: "Types of Projects your company work on",
        required: true,
        component: SelectField,
        componentProps: {
          options: [
            { text: "Residential", value: "Residential" },
            { text: "Commercial", value: "Commercial" },
            { text: "Both", value: "Both" },
          ]
        }
      },
      {
        id: "businessWebsite",
        label: "Business Website",
        required: false,
        component: TextField,
      },
      {
        id: "monthlyRevenue",
        label: "Monthly Revenue",
        required: true,
        component: CurrencyField,
      },
      {
        id: "yearsInBusiness",
        label: "Years in Business",
        required: true,
        component: NumberField,
      },
    ]
  },
  { 
    title: "Business Address",
    fields: [
      {
        id: "street",
        label: "Street",
        required: false,
        component: TextField,
      },
      {
        id: "city",
        label: "City",
        required: false,
        component: TextField,
      },
      {
        id: "states",
        label: "State",
        required: false,
        component: SelectField,
        componentProps: {
          optionListName: "states"
        }
      },
      {
        id: "zipCode",
        label: "Zip Code",
        required: false,
        component: TextField,
      },
    ]
  },
]

export default ADMIN_USER_CREATION_FORM_FIELDS