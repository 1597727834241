// Libs
import { useEffect, useState } from "react"
import { jwtDecode } from "jwt-decode"
import { useNavigate } from "react-router-dom"
// Utils
import { ADMINISTRATOR_ROLE_NAME } from "../utils/roles";

export const useRole = (requiredRole) => {
  const [isAllowed, setIsAllowed] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const userToken = localStorage.getItem("token")
    if (!userToken) return

    const decodedUserToken = jwtDecode(userToken)
    const userHasRolesField = !!decodedUserToken.roles

    if (!userHasRolesField) {
      localStorage.removeItem("token")
      return navigate("/login")
    }

    const isUserAdmin = decodedUserToken.roles.name === ADMINISTRATOR_ROLE_NAME || decodedUserToken.isAdmin 
    const isRoleAdmin = requiredRole === ADMINISTRATOR_ROLE_NAME
    const isUserAllowed = decodedUserToken.roles.name === requiredRole || (isRoleAdmin && isUserAdmin)

    setIsAllowed(isUserAllowed)
  }, [])

  return {
    isAllowed
  }
}