import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { loginCheck } from './api.service';
import PasswordField from './forms/fields/password-field';
import TextField from './forms/fields/text-field';
import login from './login.jpg';
import logo from './logo.png';

export default function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ user: values })
    }).then(res => { return res.json() }).then(x => {
      if (x.token) {
        localStorage.setItem("token", x.token);

        if (searchParams.get('rd')) {
          window.location.replace(searchParams.get('rd'))
        }
        else if (!!x.redirectUrl) {
          window.location.replace(x.redirectUrl)
        }
        else {
          window.location.replace('/')
        }
      }
      else {
        setError("Login failed. Please check your details and try again.")
      }
    })
  }

  useEffect(() => { loginCheck(`${process.env.REACT_APP_API_URL}/app`).then(x => (x) ? navigate('/') : {}) }, [])

  return (
    <>

      <div className="flex h-screen">
        <div className="hidden max-w-md bg-yellow-200 lg:flex">
          <div className="flex flex-col px-20 m-auto">
            <img
              className="relative z-10 object-cover mx-auto mb-10 rounded-full w-72 h-72"
              src={login}
            />
            <div className="mt-5 text-xl font-medium text-center text-neutral-700">Construction finances made simple</div>

          </div>
        </div>
        <div className="flex flex-col flex-1 bg-white">
          <div className="w-full max-w-xl m-auto">
            <img src={logo} className="mx-auto mt-auto mb-10 w-80" />
            <div className="p-10 px-20 lg:bg-secondary">
              <div className="mb-5 text-4xl font-medium text-center text-neutral-800">Welcome Back!</div>
              <div className="mb-10 text-lg font-medium text-center text-neutral-800">Enter your BuildrFi account details to continue</div>
              <div>
                <label className="block mt-5 text-sm font-medium text-left text-neutral-800">
                  {error}
                </label>
              </div>
              <TextField required email label="Email" value={values.email} onChange={(val) => setValues({ ...values, email: val })}></TextField>
              <PasswordField required label="Password" value={values.password} onChange={(val) => setValues({ ...values, password: val })}></PasswordField>
              <Link className="mt-2 text-sm underline" to="/request-reset">Forgot Password</Link>

              <button
                onClick={() => submit()}
                type="button"
                disabled={!values.email || !values.password}
                className="flex justify-center w-full px-4 py-2 mt-5 font-semibold bg-yellow-500 border-2 border-yellow-500 rounded text-neutral-800 disabled:border-gray-500 disabled:bg-white disabled:text-gray-500 text-md hover:bg-yellow-600 "
              >
                Login
              </button>
            </div>
          </div>


        </div>

      </div>

    </>
  )
}
