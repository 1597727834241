import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import {
  ArrowDownIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  HomeIcon,
  TableCellsIcon,
  XMarkIcon,
  UserGroupIcon,
  ChevronDownIcon,
  ChevronRightIcon
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { api } from "./api.service";
import Loading from "./components/loading";
import logo from "./logo.png";
import { useRole } from "./hooks/useRole";
import { MANAGER_ROLE_NAME } from "./utils";

const TOP_NAVIGATION = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Projects", href: "/projects", icon: TableCellsIcon },
  { name: "Bills", href: "/bills", icon: CurrencyDollarIcon, subItems: [{ name: "Vendors", href: "/vendors" }] },
  { name: "Invoices", href: "/invoices", icon: ClipboardDocumentIcon, subItems: [{ name: "Customers", href: "/customers" }] },
];

const NON_MANAGER_BOTTOM_NAVIGATION = [
  { name: "Settings", href: "/settings", icon: Cog6ToothIcon },
  { name: "Logout", href: "/logout", icon: ArrowDownIcon },
];

const MANAGER_BOTTOM_NAVIGATION = [
  { name: "Settings", href: "/settings", icon: Cog6ToothIcon },
  { name: "Team", href: "/user", icon: UserGroupIcon },
  { name: "Logout", href: "/logout", icon: ArrowDownIcon },
]

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedItems, setExpandedItems] = useState([]);

  const { pathname } = useLocation();
  const { isAllowed: isManager } = useRole(MANAGER_ROLE_NAME);

  const bottomNavigation = isManager ? MANAGER_BOTTOM_NAVIGATION : NON_MANAGER_BOTTOM_NAVIGATION;

  useEffect(() => {
    const checkEndingStatus = () => {
      const status = user.stripeSubscriptionStatus;
      if (status?.startsWith("Ending")) {
        const dateString = status.replace("Ending on ", "");
        const endDate = new Date(dateString);
        const currentDate = new Date();
        
        if (endDate < currentDate) {
          window.location.assign("/pricing");
        }
        else
        setLoading(false);
      }
    };
  
    if (window.location.href.includes("pricing")) {
      setLoading(false);
      return;
    }
  
    if (user) {
      if (
        user.hasFreeAccess ||
        user.stripeSubscriptionStatus === "active" ||
        user.stripeSubscriptionStatus === "trialing"
      ) {
        setLoading(false);
        return;
      } else if (user.stripeSubscriptionStatus?.startsWith("Ending")) {
        checkEndingStatus();

        return;
      } else {
        window.location.assign("/pricing");
      }
    } else {
      api(`${process.env.REACT_APP_API_URL}/app/settings`).then((_user) => {
        setUser(_user ?? null);
      });
    }
  }, [user, pathname]);

  const handleToggle = (name) => {
    setExpandedItems((prev) =>
      prev.includes(name) ? prev.filter((item) => item !== name) : [...prev, name]
    );
  };

  const renderNavItems = (items) =>
    items.map((item) => (
      <div key={item.name}>
        <div
          className={classNames(
            pathname.endsWith(item.href)
              ? "bg-slate-700 text-white"
              : "text-neutral-600 hover:text-white hover:bg-slate-600",
            "group flex items-center px-2 py-2 text-base font-normal rounded"
          )}
          aria-current={item.current ? "page" : undefined}
        >
          <Link
            to={item.href}
            className="flex items-center w-full"
            onClick={() => item.subItems && handleToggle(item.name)}
          >
            <item.icon className="flex-shrink-0 w-6 h-6 mr-4" aria-hidden="true" />
            {item.name}
            {item.subItems && (
              <span className="ml-auto">
                {expandedItems.includes(item.name) ? (
                  <ChevronDownIcon className="w-5 h-5" />
                ) : (
                  <ChevronRightIcon className="w-5 h-5" />
                )}
              </span>
            )}
          </Link>
        </div>
        {item.subItems && expandedItems.includes(item.name) && (
          <div className="ml-8 space-y-1">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={subItem.href}
                className={classNames(
                  pathname.endsWith(subItem.href)
                    ? "bg-slate-700 text-white"
                    : "text-neutral-800 hover:text-white hover:bg-slate-600",
                  "group flex items-center px-2 py-2 text-base font-medium rounded"
                )}
                aria-current={subItem.current ? "page" : undefined}
              >
                <ChevronRightIcon className="flex-shrink-0 w-4 h-4 mr-4" aria-hidden="true" />
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    ));

  if (loading) return <Loading />;

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-opacity-75 bg-slate-300" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-slate-100">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img className="object-cover w-full h-12" src={logo} />
                  </div>
                  <nav
                    className="flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-slate-800"
                    aria-label="Sidebar"
                  >
                    <div className="px-2 space-y-1">
                      {renderNavItems(TOP_NAVIGATION)}
                    </div>
                    <div className="pt-6 mt-6">
                      <div className="px-2 space-y-1">
                        {bottomNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              pathname.endsWith(item.href)
                                ? "bg-slate-800 text-white"
                                : "text-neutral-800 hover:text-white hover:bg-slate-600",
                              "flex items-center px-2 py-2 text-base font-medium rounded group"
                            )}
                          >
                            <item.icon className="w-6 h-6 mr-4" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-col flex-grow px-2 pt-10 pb-4 overflow-y-auto bg-secondary">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="object-cover mx-auto w-44" src={logo} />
            </div>

            <nav
              className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-slate-800"
              aria-label="Sidebar"
            >
              <div className="flex-1 px-2 space-y-2">
                {renderNavItems(TOP_NAVIGATION)}
              </div>
              <div className="pt-6 mt-6">
                <div className="px-2 space-y-1">
                  {bottomNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        pathname.endsWith(item.href)
                          ? "bg-slate-700 text-white"
                          : "text-slate-500 hover:text-neutral-800 hover:bg-slate-300",
                        "flex items-center px-4 py-3 text-sm font-medium leading-6 rounded group",
                      )}
                    >
                      <item.icon className="w-6 h-6 mr-4" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1 min-h-screen bg-white lg:pl-64">
          <div className="flex justify-between p-2 lg:hidden">
            <img src={logo} className="h-12"></img>
            <Bars3Icon className="w-6" onClick={() => setSidebarOpen(true)} />
          </div>
          <main className="flex-1 p-3 lg:p-10">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
