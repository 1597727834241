import Ajv from "ajv";
import addFormats from "ajv-formats";

const ajv = new Ajv();
addFormats(ajv);

export const validateData = (data, schema) => {
    const validate = ajv.compile(schema);
    const valid = validate(data);
    if (!valid) {
        const errors = validate.errors.map(error => ({
            field: error.instancePath.replace('/', '') || error.params.missingProperty,
            message: error.message,
        }));
        return {
            isValid: false,
            errors,
        };
    }
    return {
        isValid: true,
        errors: [],
    };
};

export const vendorSchema = {
    type: "object",
    properties: {
        name: { type: "string", minLength: 1 },
        firstName: { type: "string", minLength: 1 },
        lastName: { type: "string", minLength: 1 },
        email: { type: "string", format: "email" },
        companyAddress: { type: "string", minLength: 1 },
        phoneNumber: { type: "string", pattern: "^\\+?[0-9\\s\\-\\(\\)]+$" },
    },
    required: ["name", "firstName", "lastName", "email", "companyAddress", "phoneNumber"],
    additionalProperties: true,
};

export const customerSchema = {
    type: "object",
    properties: {
        name: { type: "string", minLength: 1 },
        firstName: { type: "string", minLength: 1 },
        lastName: { type: "string", minLength: 1 },
        email: { type: "string", format: "email" },
        companyAddress: { type: "string", minLength: 1 },
        phoneNumber: { type: "string", pattern: "^\\+?[0-9\\s\\-\\(\\)]+$" },
    },
    required: ["name", "firstName", "lastName", "email", "companyAddress", "phoneNumber"],
    additionalProperties: true,
};
