import { Dialog, Transition } from '@headlessui/react'
import {
    ArrowDownIcon,
    LinkIcon,
    PencilIcon,
    UserGroupIcon,
    InboxStackIcon,
    XMarkIcon,
    BuildingOfficeIcon
} from '@heroicons/react/24/outline'
import { Fragment, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from './logo.png'

const navigation = [
  { name: 'Users', href: '/admin', icon: UserGroupIcon },
  { name: 'Businesses', href: '/admin/business', icon: BuildingOfficeIcon },
  { name: 'Affiliate Links', href: '/admin/affiliate-links', icon: LinkIcon },
  { name: 'Content Links', href: '/admin/content', icon: PencilIcon },
  { name: "Customers", href: "/admin/customers", icon: InboxStackIcon },
  { name: "Vendors", href: "/admin/vendors", icon: InboxStackIcon },
]
const secondaryNavigation = [
  { name: 'Logout', href: '/logout', icon: ArrowDownIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminLayout() {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-opacity-75 bg-slate-600" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-slate-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img className="object-cover w-full h-12" src={logo} />
                  </div>
                  <nav
                    className="flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-slate-800"
                    aria-label="Sidebar"
                  >
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <>
                          { item.category && <div className="text-lg">{item.category}</div> }
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              pathname.endsWith(item.href)
                                ? "bg-slate-700 text-white"
                                : "text-neutral-800 hover:text-white hover:bg-slate-600",
                              "group flex items-center px-2 py-2 text-base font-medium rounded"
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon 
                              className="flex-shrink-0 w-6 h-6 mr-4 text-slate-200" 
                              aria-hidden="true" 
                            />
                            {item.name}
                          </Link>
                        </>
                      ))}
                    </div>
                    <div className="pt-6 mt-6">
                      <div className="px-2 space-y-1">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center px-2 py-2 text-base font-medium rounded group text-neutral-800 hover:bg-slate-600 hover:text-white"
                          >
                            <item.icon className="w-6 h-6 mr-4 text-slate-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-col flex-grow px-2 pt-10 pb-4 overflow-y-auto bg-slate-300">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="object-cover mx-auto w-44" src={logo} />
            </div>

            <nav className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-slate-800" aria-label="Sidebar">
              <div className="flex-1 px-2 space-y-2">
                {navigation.map((item) => (
                  <>
                    { item.category && <div className="pt-5 ml-3 text-xs font-semibold text-slate-500">{item.category}</div> }
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        pathname.endsWith(item.href)
                          ? "bg-slate-700 text-white"
                          : "text-slate-500 hover:text-neutral-800 hover:bg-slate-400",
                        "group flex items-center px-4 py-3 text-sm leading-6 font-medium rounded"
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon 
                        className="flex-shrink-0 w-6 h-6 mr-4" 
                        aria-hidden="true" 
                      />
                      {item.name}
                    </Link>
                  </>

                ))}
              </div>
              <div className="pt-6 mt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        pathname.endsWith(item.href)
                          ? "bg-slate-700 text-white"
                          : "text-slate-500 hover:text-neutral-800 hover:bg-slate-400",
                        "group flex items-center px-4 py-3 text-sm leading-6 font-medium rounded"
                      )}
                    >
                      <item.icon className="w-6 h-6 mr-4" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div className="flex flex-col flex-1 min-h-screen lg:pl-64 bg-slate-50">
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  )
}
