// Libs
import { useNavigate, useParams ,useLocation} from "react-router-dom";
// Forms
import PhoneField from "./fields/phone-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

export default function VendorForm({ ...props }) {
    const params = useParams();
    const navigate = useNavigate();
    const location=useLocation();

    const id = params.id;
    const handleCallback = () => {
        if (location.pathname.startsWith("/admin/vendor")) {
            navigate("/admin/vendors");
        } else {
            navigate("/bills");
        }
    };
    return <div className="sm:px-4">
        <FormWrapper url="app/admin/vendor" id={id} {...props} callback={handleCallback} validate={() => true}>
            {(values, setValues) =>
                <>
                    <div className="text-3xl font-bold tracking-tighter text-neutral-800">New Vendor Details</div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <TextField label="Company Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })} required />
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}