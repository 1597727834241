// Libs
import { useNavigate, useParams ,useLocation} from "react-router-dom";
// Forms
import PhoneField from "./fields/phone-field";
import TextField from "./fields/text-field";
import FormWrapper from "./form-wrapper";

export default function CustomerForm({ ...props }) {
    const params = useParams();
    const navigate = useNavigate();
    const location=useLocation();

    const id = params.id;
    const handleCallback = () => {
        if (location.pathname.startsWith("/admin/customer")) {
            navigate("/admin/customers");
        } else {
            navigate("/customers");
        }
    };
    return <div className="sm:px-4">
        <FormWrapper url="app/customer" id={id} {...props} callback={handleCallback} validate={() => true}>
            {(values, setValues) =>
                <>
                    <div className="text-3xl font-bold tracking-tighter text-neutral-800">New Customer Details</div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <TextField label="Company Name" value={values.name} onChange={(val) => setValues({ ...values, name: val })} required />
                        <TextField label="Customer Contact First Name" value={values.firstName} onChange={(val) => setValues({ ...values, firstName: val })} required />
                        <TextField label="Customer Contact Last Name" value={values.lastName} onChange={(val) => setValues({ ...values, lastName: val })} required />
                    </div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                        <TextField label="Customer Contact Email" value={values.email} onChange={(val) => setValues({ ...values, email: val })} required />
                        <PhoneField label="Customer Contact Phone Number" value={values.phoneNumber} onChange={(val) => setValues({ ...values, phoneNumber: val })} required />
                        <TextField label="Customer Contact Company Address" value={values.companyAddress} onChange={(val) => setValues({ ...values, companyAddress: val })} required />
                    </div>
                </>
            }
        </FormWrapper>
    </div>
}