// Libs
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// API service
import { api, api_delete } from "../api.service";
// Components
import PageHeader from "./page-header";
import Loading from "./loading";
import Button from "./button";
import Table from "./table";
import Confirm from "./confirm";

const USER_TABLE_COLUMNS = [
  {
    Header: "Business Name",
    accessor: "businessName",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "roles",
  },
  {
    Header: "Edit",
    accessor: "edit"
  }
]

const EXPORT_TABLE_COLUMNS = [
  "Business Name",
  "Name",
  "Email",
  "Role",
  "Business Type",
  "Project Types",
  "Annual Revenue",
  "Years In Business",
  "Website",
  "Street",
  "City",
  "State",
  "Zip Code",
]

const CellEditUser = ({ editCallback }) => (
  <span
    className="underline cursor-pointer"
    onClick={editCallback}
  >
    Edit
  </span>
)

export default function UsersList() {
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const areThereUsers = useMemo(() => (users?.length > 0), [users])

  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/user/${deleteId}`).then(
      (x) => {
        api(`${process.env.REACT_APP_API_URL}/user`)
          .then((x) => setUsers(x))
          .then((x) => setDeleteId(null));
      }
    );
  };

  useEffect(() => {
    (async () => {
      const response = await api(`${process.env.REACT_APP_API_URL}/app/user`)
      setUsers(response)
    })()
  }, []);

  if (!areThereUsers) return <Loading />;

  return (<>
    <Confirm
      open={!!deleteId}
      cancel={() => setDeleteId(false)}
      confirm={() => deleteConfirm(deleteId)}
    />
    <PageHeader
      headline={"Team"}
      actions={
        <Button
          text="Add user"
          onClick={() => navigate("/user/create")}
        />
      }
    />
    <div className="flex">
      <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
        <Table
          columns={USER_TABLE_COLUMNS}
          exportColumns={EXPORT_TABLE_COLUMNS}
          data={users.map((user) => ({
            ...user,
            roles: user.roles?.name,
            hasFreeAccess: user.hasFreeAccess ? "Yes" : "No",
            edit: <CellEditUser key={user._id} editCallback={() => navigate(`/user/${user._id}/edit`)} />,
          }))}
          exportData={users.map((user) => ({
            businessName: user.businessName ?? "",
            name: user.name ?? "",
            email: user.email ?? "",
            roles: user.roles?.name ?? "",
            businessType: user.businessType ?? "",
            businessOverview: user.businessOverview ?? "",
            annualRevenue: user.annualRevenue ?? "",
            yearsInBusiness: user.yearsInBusiness ?? "",
            businessWebsite: user.businessWebsite ?? "",
            street: user.street ?? "",
            city: user.city ?? "",
            state: user.state ?? "",
            zipCode: user.zipCode ?? ""
          }))}
        />
      </div>
    </div>
  </>);
}
