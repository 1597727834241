// Libs
import { useEffect, useMemo, useState } from "react";
// API service
import { api } from "../api.service";
// Components
import PageHeader from "./page-header";
import Loading from "./loading";
import Table from "./table";
import { PlusIcon } from '@heroicons/react/20/solid';
import Button from './button';
import { useNavigate } from 'react-router-dom';

const USER_TABLE_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Address",
    accessor: "companyAddress",
  },
  {
    Header: "Phone",
    accessor: "phoneNumber"
  }
]

const EXPORT_TABLE_COLUMNS = [
  "Name",
  "First Name",
  "Last Name",
  "Email",
  "Address",
  "Phone",
]

export default function VendorsList() {
  const [vendors, setVendors] = useState(null);
  const navigate = useNavigate();
  const areThereVendors = useMemo(() => (vendors?.length > 0), [vendors])

  useEffect(() => {
    (async () => {
      const response = await api(`${process.env.REACT_APP_API_URL}/app/admin/vendor`)
      setVendors(response)
    })()
  }, []);

  if (!areThereVendors) return <Loading />;

  return (<>
    <PageHeader headline={"Vendors"} />
    <div className="flex">
      <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
        <Table
          columns={USER_TABLE_COLUMNS}
          exportColumns={EXPORT_TABLE_COLUMNS}
          data={vendors.map((vendor) => ({
            ...vendor,
          }))}
          exportData={vendors.map((vendor) => ({
            name: vendor.name ?? "",
            firstName: vendor.firstName ?? "",
            lastName: vendor.lastName ?? "",
            email: vendor.email ?? "",
            companyAddress: vendor.companyAddress ?? "",
            phoneNumber: vendor.phoneNumber ?? "",
          }))}
        />
         <div className="mt-5">
          <Button onClick={() => navigate('/admin/vendor/create')} text={<> <PlusIcon className="w-5 h-5"></PlusIcon> Vendor</>} />
        </div>
      </div>
    </div>
  </>);
}
