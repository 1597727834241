import { Link } from "react-router-dom";

export default function Button(props) {
  const className = `${props.disabled ? "opacity-20" : ""} ${props.className} ${
    !props.className?.includes("bg-") ? "bg-yellow-500 hover:bg-yellow-600" : ""
  } ${
    !props.className?.includes("text-") ? "text-neutral-800" : ""
  } bg-yellow-500  cursor-pointer justify-center inline-flex items-center rounded border border-transparent px-4 py-2 text-sm font-medium  shadow-sm focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 transition-all `;

  if (props.link) {
    return (
      <Link className={className} to={props.link}>
        {props.text}
      </Link>
    );
  }
  return (
    <div
      title={props.title}
      className={className}
      onClick={(e) => (props.disabled ? {} : props.onClick(e))}
    >
      {props.text}
    </div>
  );
}
