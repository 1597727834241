import { api, api_delete } from '../../api.service';

export const apiFetchVendors = async () => {
    try {
        const result = await api(`${process.env.REACT_APP_API_URL}/app/vendor`);
        return result ?? [];
    } catch (error) {
        console.error("Error fetching vendors:", error);
        return [];
    }
};

export const apiUpdateVendor = async (editData) => {
    try {
        const response = await api(`${process.env.REACT_APP_API_URL}/app/vendor`, { data: editData });
        return response;
    } catch (error) {
        console.error("Error updating vendor:", error);
        throw error;
    }
};

export const apiDeleteVendor = async (id) => {
    try {
        const response = await api_delete(`${process.env.REACT_APP_API_URL}/app/vendor/${id}`);
        return response;
    } catch (error) {
        console.error("Error deleting vendor:", error);
        throw error;
    }
};