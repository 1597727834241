import { useEffect, useState } from 'react';
import { api } from '../api.service';
import Loading from './loading';

export default function LinkPreview({ url, title, description }) {
    const [data, setData] = useState(null)

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/app/content-link`, { data: { url: url } }).then(x => setData(x))
    }, [])

    if (!data) return <Loading></Loading>

    return (<>
        <article
            key={data.id}
            className="relative flex flex-col justify-end h-full px-8 pt-40 pb-8 overflow-hidden bg-gray-900 rounded hover:opacity-100 opacity-80 isolate sm:pt-24 lg:pt-24 border-2"
        >
            <img src={data.images ? data.images[0] : ''} alt="" className="absolute inset-0 object-cover w-full h-full -z-10" />
            <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />

            <div className="flex flex-wrap items-center overflow-hidden text-sm leading-6 text-gray-300 gap-y-1">

                <div className="flex items-center -ml-4 gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="flex gap-x-2.5">
                        <img src={data.favicons ? data.favicons[0] : ''} alt="" className="flex-none w-6 h-6 rounded-full bg-white/10" />
                        {data.siteName}
                    </div>
                </div>
            </div>
            <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <a target={'_blank'} href={data.url}>
                    <span className="absolute inset-0" />
                    {data.title}
                </a>
            </h3>
            <p className="mt-3 text-lg font-medium leading-6 text-gray-100">
                {description}
            </p>
        </article>

    </>

    )
}
