import '@lendica/ibranchtrigger';
import { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { api } from './api.service';
import Button from './components/button';
import HelpPopup from './components/help-popup';
import LinkPreview from './components/link-preview';
import FormModal from './forms/form-modal';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const [data, setData] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [activeRevenue, setActiveRevenue] = useState();
  const [percentageOwed, setPercentageOwed] = useState(0);
  const [suggestedCredit, setSuggestedCredit] = useState();
  const [total, setTotal] = useState({});
  const [showWelcomeModal, setShowWelcomeModal] = useState(!localStorage.getItem("w"));
  const [initialised, setInitialised] = useState(false);
  const navigate = useNavigate();

  useEffect(() => { api(`${process.env.REACT_APP_API_URL}/app`).then(x => { setData(x) }) }, [])

  useEffect(() => {
    let activetotal = 0;
    let total = 0;
    let owed = 0;
    let paid = 0;
    let totalSpend = 0;
    let totalEstimatedSpend = 0;
    if (data?.projects?.length)
      for (let p of data.projects) {
        let totals = getItemTotals(p._id, p.deposit)
        if (p.totalPrice && p.status == "Active")
          activetotal += +p.totalPrice;
        if (p.totalPrice)
          total += +p.totalPrice;
        if (totals.totalPaidToDate)
          paid += totals.totalPaidToDate;
        if (totals.totalOutstanding)
          owed += totals.totalOutstanding
        if (totals.totalCosts)
          totalSpend += totals.totalCosts;
        if (p.totalEstimatedCosts)
          totalEstimatedSpend += +p.totalEstimatedCosts;
      }
    setPercentageOwed(Math.round((owed / total * 100) || 0))
    setTotalRevenue(total)
    setActiveRevenue(activetotal)
    setSuggestedCredit(Math.round(owed * 1.75))
    let percentageComplete = totalSpend / totalEstimatedSpend * 100;
    if (isNaN(percentageComplete)) percentageComplete = 0
    percentageComplete = Math.round(percentageComplete)
    setTotal({ total, owed, paid, totalSpend, totalEstimatedSpend, percentageComplete })
  }, [data])

  const linksRef = useRef()
  const linksVisible = useIntersectionObserver(linksRef, { rootMargin: '0px', threshold: 0.2 })
  useEffect(() => {
    if (linksVisible) {
      linksRef.current.classList.remove('invisible')
      linksRef.current.classList.add('animate-fade-up')
    }
  }, [linksVisible])

  const contentRef = useRef()
  const contentVisible = useIntersectionObserver(contentRef, { rootMargin: '0px', threshold: 0.2 })
  useEffect(() => {
    if (contentVisible) {
      contentRef.current.classList.remove('invisible')
      contentRef.current.classList.add('animate-fade-up')
    }
  }, [contentVisible])

  useEffect(() => {
    if (window.lendica && data && !initialised) {

      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: data.companyData.id,
        company_name: data.companyData.companyName
      };

      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
        setInitialised(true)
      });
    }
  }, [window.lendica, data])

  const openIBranch = () => {
    window.lendica.ibranch.open();
  }

  const getItemTotals = (projectId, depositPaid) => {
    let totalOutstanding = 0
    let totalPaidToDate = +(depositPaid ?? 0)
    let totalCosts = 0
    for (let i of data.invoices.filter(x => x.project == projectId)) {
      if (i.paid) {
        totalPaidToDate += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
      else {
        totalOutstanding += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
    }
    for (let b of data.bills.filter(x => x.project == projectId)) {
      totalCosts += +(b.tax ?? 0) + +(b.subtotal ?? 0);
    }
    return { totalOutstanding, totalPaidToDate, totalCosts }
  }

  return (
    <div className="flex flex-col">

      <FormModal open={!!showWelcomeModal} setOpen={() => { localStorage.setItem("w", "t"); setShowWelcomeModal(false) }}>
        <div className="text-2xl font-semibold text-center text-neutral-800">
          Welcome to BuildrFi
        </div>
        <div className="mt-5 mb-3 text-lg text-neutral-600">With BuildrFi, you can manage project cash flows and get access to embedded financing based on business and project data - <b>all from one place.</b> </div>
        <div className="font-medium text-neutral-700">
          Follow these steps to unlock working capital solutions for your business:

        </div>
        <div>
          <div className="mt-2 text-neutral-700"><div className="inline-flex items-center justify-center w-8 h-8 mr-5 text-center bg-yellow-500 rounded-full"><div>1</div></div>From the Projects tab, Click "+ Project" to add a new project</div>
          <div className="mt-2 text-neutral-700"><div className="inline-flex items-center justify-center w-8 h-8 mr-5 text-center bg-yellow-500 rounded-full"><div>2</div></div>Complete project details</div>
          <div className="mt-2 text-neutral-700"><div className="inline-flex items-center justify-center w-8 h-8 mr-5 text-center bg-yellow-500 rounded-full"><div>3</div></div>From Project Details, Click "+ New Bill" to add Bills, "+ New Invoice" to add Invoices</div>
          <div className="mt-2 text-neutral-700"><div className="inline-flex items-center justify-center w-8 h-8 mr-5 text-center bg-yellow-500 rounded-full"><div>4</div></div>Unlock "Pay Later" extend supplier terms +8 weeks & "Fund Now" to advance up to 90% of outstanding Pay Apps and Invoices</div>
        </div>
      </FormModal>

      <div>
        <div className="w-full max-w-6xl p-5 mx-auto sm:p-0">
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-4">

            <div className="grid grid-cols-1 gap-5 lg:col-span-3 lg:grid-cols-2">

              {/* Active projects */}
              <div className="h-full p-6 bg-secondary rounded-lg shadow flex flex-col items-center animate-fade-right border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-700 sm:text-xl">Active Projects</div>
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-800 sm:text-3xl">{data?.projects?.length ? data?.projects?.filter(x => x.status == "Active").length : '0'}</div>
                {/* Add button */}
                <Button onClick={() => {navigate('/project/new')}} text={
                  data?.projects?.length
                    ? data.projects.filter(x => x.status === "Active").length
                      ? 'Add a New Project'
                      : 'Create your first Project'
                    : 'Create your first Project'
                } className="w-3/4 border-2 hover:scale-105 border-yellow-800 p-3 px-6 !rounded-full !shadow-md animate-fade !text-base" />
              </div>

              {/* Active Projects Contract Value */}
              <div className="p-6 flex flex-col bg-secondary rounded-lg shadow animate-fade-right animate-delay-100 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-700 sm:text-xl">Active Projects Contract Value</div>
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-800 sm:text-3xl">{activeRevenue ? `$${activeRevenue.toLocaleString()}` : '0'}</div>
              </div>

              {/* Percentage Complete */}
              <div className="relative flex flex-col items-center h-full p-6 mb-5 bg-secondary rounded-lg shadow animate-fade-right animate-delay-200 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-700 sm:text-xl">Percentage Complete</div><div className="absolute right-10 top-8"><HelpPopup text="% costs to date out of total estimated costs for all projects"></HelpPopup></div>
                <div ><CircularProgressbar styles={buildStyles({

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 2,

                  // Colors
                  pathColor: `rgb(245, 205, 70)`,
                  textColor: 'black',
                  trailColor: '#d6d6d6',
                })} className="w-24 h-24 " value={percentageOwed} text={`${percentageOwed}%`} /></div>
                {/* <div className="text-xl font-bold tracking-tight text-neutral-800 sm:text-3xl">{percentageOwed ? `${percentageOwed}%` : '...'}</div> */}
              </div>

              {/* Paid to date */}
              <div className="relative flex flex-col items-center h-full p-6 mb-5 bg-secondary rounded-lg shadow animate-fade-right animate-delay-200 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-neutral-700 sm:text-xl">Paid to Date - ${total.paid}</div>
                <div ><CircularProgressbar styles={buildStyles({

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 2,

                  // Colors
                  pathColor: `rgb(245, 205, 70)`,
                  textColor: 'black',
                  trailColor: '#d6d6d6',
                })} className="w-24 h-24 " value={(total.paid / total.total * 100) || 0} text={`${Math.round((total.paid / total.total * 100) || 0)}%`} /></div>
              </div>

              {/* Credit Line Estimate */}
              <div className="h-full p-6 text-center bg-yellow-200 rounded-lg shadow border">
                <div className="text-xl font-bold tracking-tight text-center text-neutral-800 sm:text-xl mb-8">Estimated Credit</div>
                <div className="mb-8 text-5xl font-bold tracking-tight text-neutral-800">${suggestedCredit?.toLocaleString()}</div>
                <div className="max-w-lg mx-auto text-sm text-slate-700">Based on your project pipeline and incoming payments you could apply for ${suggestedCredit?.toLocaleString()} in credit</div>
              </div>


              {/* Lendica Widget */}
              <div className="h-full p-6 text-center bg-yellow-200 rounded-lg shadow border">
                <div className="text-xl font-bold tracking-tight text-center text-neutral-800 sm:text-xl mb-8">Unlock your potential with BuildrFi!</div>
                <div className="max-w-lg mx-auto mb-8 text-sm text-slate-700">Manage your cashflow and access capital on a single platform</div>
                {initialised && <Button onClick={() => openIBranch()} text="Apply Now" className="border-2 border-yellow-800 p-3 px-6 rounded-full !shadow-md animate-fade mt-auto w-3/4 hover:scale-105 !text-base" />}
              </div>

            </div>

            {/* Resources */}
            <div >
              <div className="text-xl font-medium text-neutral-800 mb-3">Resources for you</div>
              <div ref={contentRef} className="grid invisible grid-cols-1 gap-4">
                {data?.content?.map((x) => <div key={x.title} className="hover-lift">
                  <LinkPreview url={x.url} description={x.description} />
                </div>)}
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}


function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}