import React from 'react';

const TableCell = ({ id, value, editMode, editData, setEditData, type = "text", className = "", isHidden = false }) => {
    return (
        <div className={`${className} ${isHidden ? 'hidden lg:block' : ''} p-5 font-medium text-sm border-b text-neutral-800 border-slate-500 break-words`}>
            {editMode ? (
                <input
                    id={id}
                    type={type}
                    value={editData[id]}
                    onChange={(e) => setEditData({ ...editData, [id]: e.target.value })}
                    className="w-full"
                />
            ) : (
                value ?? "-"
            )}
        </div>
    );
};

export default TableCell;
