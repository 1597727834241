// Libs
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Api Service
import { api } from "../api.service";
// Components
import Loading from "./loading";
import PageHeader from "./page-header";
import Table from "./table";

const EXPORT_TABLE_COLUMNS = [
  "Name",
  "Type",
  "Overview",
  "Website",
  "Years In Business",
  "Monthly Revenue",
  "Users Limit",
]

const BUSINESS_TABLE_COLUMN = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Overview",
    accessor: "overview",
  },
  {
    Header: "Website",
    accessor: "website",
  },
  {
    Header: "Years In Business",
    accessor: "yearsInBusiness",
  },
  {
    Header: "Monthly Revenue",
    accessor: "monthlyRevenue",
  },
  {
    Header: "Users Count",
    accessor: "usersCount",
  },
  {
    Header: "Users Limit",
    accessor: "usersLimit",
  },
  {
    Header: "Add User",
    accessor: "addUser",
  },
]

const CellAddUser = ({ addUserCallback }) => (
  <span
    className="underline cursor-pointer"
    onClick={addUserCallback}
  >
    Add User
  </span>
)

const BusinessList = () => {
  const [businesses, setBusinesses] = useState([])
  const navigate = useNavigate();
  const areThereBusinesses = !!businesses && businesses.length > 0

  useEffect(() => {
    (async () => {
      const response = await api(`${process.env.REACT_APP_API_URL}/admin/business`)
      setBusinesses(response)
    })()
  }, []);

  if (!areThereBusinesses) return <Loading />;

  return (<>
    <PageHeader headline={"Businesses"} />
    <div className="flex">
      <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
        <Table
          columns={BUSINESS_TABLE_COLUMN}
          exportColumns={EXPORT_TABLE_COLUMNS}
          data={businesses.map((business) => ({
            ...business,
            addUser: <CellAddUser key={business._id} addUserCallback={() => navigate(`/admin/business/${business._id}/user/create`)} />,
          }))}
          exportData={businesses.map((business) => ({
            name: business.name ?? "",
            type: business.type ?? "",
            overview: business.overview ?? "",
            website: business.website ?? "",
            yearsInBusiness: business.yearsInBusiness ?? "",
            monthlyRevenue: business.monthlyRevenue ?? "",
            usersCount: business.usersCount ?? "",
            usersLimit: business.usersLimit ?? ""
          }))}
        />
      </div>
    </div>
  </>)
}

export default BusinessList