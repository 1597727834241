// Libs
import { Route, Routes } from "react-router-dom";
// Admin
import AdminAffiliateLinks from "./admin-affiliate-links";
import AdminContent from "./admin-content";
import AdminLayout from "./admin-layout";
import AdminUsers from "./admin-users";
// Form
import AffiliateLinkForm from "./forms/affiliate-link-form";
import BillForm from "./forms/bill-form";
import ContentForm from "./forms/content-form";
import InvoiceForm from "./forms/invoice-form";
import ProfileForm from "./forms/profile-form";
import ProjectForm from "./forms/project-form";
import UserForm from "./forms/user-form";
import VendorFormAdmin from "./forms/vendor-form-admin";
import VendorForm from "./forms/vendor-form";
import CustomerForm from "./forms/customer-form";

import ADMIN_USER_CREATION_FORM_FIELDS from "./forms/fields/admin-user-creation-fields";
import { 
  CUSTOMER_USER_CREATION_FORM_FIELDS, 
  CUSTOMER_USER_UPDATING_FORM_FIELDS 
} from "./forms/fields/customer-user-creation-fields";
// Pages
import Home from "./home";
import Invoices from "./invoices";
import Layout from "./layout";
import Login from "./login";
import Logout from "./logout";
import Pricing from "./pricing";
import Projects from "./projects";
import Bills from "./bills";
import Register from "./register";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
// Components
import UsersList from "./components/users-list";
import BusinessList from "./components/businesses-list";
import VendorList from "./components/vendors-list-admin";
import CustomerList from "./components/customers-list-admin";
import CustomersList from "./components/customers-list";
import VendorsList from "./components/vendors-list"
import { RoleWrapper } from './wrappers';
// Utils
import { ADMINISTRATOR_ROLE_NAME, MANAGER_ROLE_NAME } from "./utils";

export default function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/pricing" element={<Pricing />} />

      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />

        <Route path="/projects" element={<Projects />} />
        <Route path="/project/:id" element={<ProjectForm />} />

        <Route path="/bill/create" element={<BillForm showBackButton noId noLoad />} />
        <Route path="/bill/:id" element={<BillForm showBackButton />} />
        <Route path="/bills" element={<Bills />} />
        <Route path="/vendor/create" element={<VendorForm showBackButton noId noLoad/>} />

    
        <Route path="/invoice/create" element={<InvoiceForm showBackButton noId noLoad/>} />
        <Route path="/invoice/:id" element={<InvoiceForm showBackButton />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/customer/create" element={<CustomerForm showBackButton noId noLoad/>} />
        <Route path="/settings" element={<ProfileForm />} />
        
        <Route path="/user" element={<RoleWrapper requiredRole={MANAGER_ROLE_NAME} />}>
          <Route index element={<UsersList />} />

          <Route path="/user/create" element={
            <UserForm 
              fieldSections={CUSTOMER_USER_CREATION_FORM_FIELDS}
              headline="Create new user" 
              successRedirectUrl="/user"
              endpointUrl="app/user"
              isSubscriptionDetailsEnabled={false}
              showBackButton 
              noLoad
              noId
            />
          } />
          <Route path="/user/:id/edit" element={
            <UserForm 
              fieldSections={
            
            

          CUSTOMER_USER_UPDATING_FORM_FIELDS}

              headline="Edit user information" 
              successRedirectUrl="/user"
              endpointUrl="app/user"
              isSubscriptionDetailsEnabled={false}
              showBackButton 
            />
          } />
        </Route>
        <Route path="/customers" element={<CustomersList />} /> 
        <Route path="/vendors" element={<VendorsList />} />
      </Route>

      <Route path="/admin" element={<RoleWrapper requiredRole={ADMINISTRATOR_ROLE_NAME} />}>
        <Route path="" element={<AdminLayout />}>
          <Route index element={<AdminUsers />} />

          <Route path="/admin/manage-user/:id" element={
            <UserForm 
              fieldSections={ADMIN_USER_CREATION_FORM_FIELDS}
              headline="Manage user"
              successRedirectUrl="/admin" 
              endpointUrl="admin/user"
              isSubscriptionDetailsEnabled
              showBackButton 
            />
          } />

          <Route path="/admin/vendors" element={<VendorList />} />
          <Route path="/admin/vendor/create" element={<VendorFormAdmin showBackButton noId noLoad />} />

          <Route path="/admin/customers" element={<CustomerList />} />

          <Route path="/admin/business" element={<BusinessList />} />
          <Route path="/admin/business/:id/user/create" element={
            <UserForm 
              fieldSections={CUSTOMER_USER_CREATION_FORM_FIELDS}
              headline="Add user to business" 
              successRedirectUrl="/admin/business"
              endpointUrl="app/user"
              isSubscriptionDetailsEnabled={false}
              showBackButton 
              noLoad
              noId
            />} 
          />

          <Route path="/admin/affiliate-links" element={<AdminAffiliateLinks />} />
          <Route path="/admin/manage-affiliate-link/:id" element={<AffiliateLinkForm />} />
          <Route path="/admin/customer/create" element={<CustomerForm showBackButton noId noLoad/>} />
          <Route path="/admin/content" element={<AdminContent />} />
          <Route path="/admin/manage-content/:id" element={<ContentForm />} />
        </Route>
      </Route>
    </Routes>
  )
}
