import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';

export default function RequestReset() {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    await fetch(`${process.env.REACT_APP_API_URL}/auth/request-token`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(values)
    }).then(x => {
      setSubmitted(true)
    })
  }

  if (submitted) {
    return <>
      <div className="flex flex-col h-screen bg-secondary">
        <div className="flex flex-col justify-center max-h-screen">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="w-64 h-auto mx-auto mb-2"
              src={logo}
            />
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="px-4 py-8 bg-white rounded shadow sm:px-10">
              <div className="text-xl font-medium text-center text-neutral-800">Instructions sent</div>
              <div className="my-5 text-sm font-light text-center text-neutral-500">
                If that email address exists, instructions for resetting your password have been sent to your email.
              </div>
              <div className="my-5 text-sm font-light text-center text-neutral-500">
                You'll receive this email within 5 minutes. Be sure to check your spam folder, too.
              </div>
              <div className="space-y-6">

                <div>
                  <button
                    onClick={() => navigate('/login')}
                    type="button"
                    disabled={!values.email || values.email != values.confirmEmail}
                    className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-yellow-500 border border-transparent rounded shadow-sm text-md hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  >
                    Go back to login
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <>
      <div className="flex flex-col h-screen bg-slate-50">
        <div className="flex flex-col justify-center flex-1 max-h-screen">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="w-64 h-auto mx-auto mb-2"
              src={logo}
            />
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="px-4 py-8 bg-white rounded shadow sm:px-10">
              <div className="text-xl font-medium text-center text-neutral-800">Forgot password?</div>
              <div className="my-5 text-sm font-light text-center text-neutral-500">Enter the email address associated with your account, and we'll email you a link to reset your password.</div>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-center text-neutral-800">
                    {error}
                  </label>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-800">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-800">
                    Confirm Email address
                  </label>
                  <div className="mt-1">
                    <input value={values.confirmEmail} onChange={(e) => setValues({ ...values, confirmEmail: e.target.value })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    onClick={submit}
                    type="button"
                    disabled={!values.email || values.email != values.confirmEmail}
                    className="flex justify-center w-full px-4 py-2 font-semibold text-white bg-yellow-500 border border-transparent rounded shadow-sm text-md hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  >
                    Send reset link
                  </button>
                  <div className="my-5 text-sm text-center text-neutral-500">
                    Go back to <a className="text-yellow-400 hover:text-yellow-500" href="/login">Sign In</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}
